.auth-form-comp {
  width: 400px;
  margin: 50px 0;
}

.auth-form-comp .login-heading {
  text-align: center;
  margin-bottom: 25px;
}

.auth-form-comp .logo-icon {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.auth-form-comp small {
  color: red !important;
  opacity: 0.8;
}

.auth-form-comp form fieldset .submit-group {
  margin-top: 35px;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.auth-form-comp form fieldset .submit-group button {
  margin-right: 20px;
  width: 120px;
}
.auth-form-comp form fieldset .submit-group button span {
  margin-left: 5px;
  color: #fff;
}

.auth-form-comp form fieldset .submit-group span {
  color: #2a9fd6;
  cursor: pointer;
}
.auth-form-comp form fieldset .submit-group span:hover {
  opacity: 0.8;
}
