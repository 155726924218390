.auth-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.auth-page .copyright {
  position: relative;
  margin-top: 100px;
}
