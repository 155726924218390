.chatbot-img {
  position: fixed;
  left: 6vh;
  bottom: 5vh;
  cursor: grab;
}

.chatbot-img img {
  width: 80px;
  height: 80px;
  animation-name: chatbot;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

@keyframes chatbot {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(20deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-20deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
