.modal-comp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 380px;
  height: 200px;
  border-radius: 0.375rem;
  background-color: #222;
  cursor: grab;
  position: fixed;
  bottom: 2vh;
  right: 2vh;
  z-index: 1;
}

.modal-comp div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}
.modal-comp div:first-child {
  height: 60px;
}
.modal-comp div:nth-child(2) {
  height: 80px;
  overflow-x: scroll;
  border-top: solid 1px rgb(41, 39, 39);
  border-bottom: solid 1px rgb(41, 39, 39);
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.modal-comp div:nth-child(2)::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.modal-comp div:last-child {
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.modal-comp .footer .auth-key-control-btn {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  padding: 0;
}

.modal-comp .body input {
  display: block;
  width: 100%;
  border: none !important;
  background-color: #222;
  padding: 10px;
  border-radius: 0.375rem;
}

.modal-comp .header h5 {
  margin: 0;
}
.modal-comp .btn-close {
  cursor: pointer !important;
  z-index: 1;
}
.modal-comp .save-change {
  width: 75px;
  margin-right: 10px;
}
.modal-comp .clear-change {
  width: 75px;
}
