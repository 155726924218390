nav ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin: 3vh 0 0 7vh;
}

nav ul li {
  margin: 20px 0;
  font-size: 1rem;
  display: inline;
}

nav ul li a {
  text-decoration: none;
  padding-top: 15px;
  margin: 0 10px;
  color: #fff;
}
nav ul li a:hover {
  color: #77b300;
}
nav ul li a.active {
  color: #77b300;
  border-top: solid 3px #77b300;
  font-weight: 600;
}
