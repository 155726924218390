.btn-group-vertical {
  position: absolute !important;
  z-index: 1;
  transform: translateX(-150%);
}

@media (max-width: 1310px) {
  .btn-group-vertical {
    position: relative !important;
    transform: translateX(0);
    margin: 50px 0 100px;
  }
}

.btn-group-vertical button {
  min-width: 150px !important;
}
