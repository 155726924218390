.chat-bot-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.key-icon {
  position: fixed;
  top: 3vh;
  left: 3vh;
  font-size: 40px;
  padding: 10px;
  border: solid 3px #77b300;
  border-radius: 50%;
  color: #fff;
  opacity: 1;
  cursor: pointer;
}
.key-rotate {
  animation-name: key-rotate;
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes key-rotate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
