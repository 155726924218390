.history-comp {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2vh;
  width: 300px;
  height: 96vh;
  overflow-y: auto;
  margin: auto;
  z-index: 0;
}
@media (min-width: 1500px) {
  .history-comp {
    min-width: 300px;
    width: 20vw;
  }
}

.history-comp .accordion-item {
  border-color: #222 !important;
}

.history-comp .accordion-button.collapsed:hover {
  color: #fff !important;
  font-weight: 600;
}
.history-comp .accordion-button:not(.collapsed) {
  background-color: #77b300 !important;
  color: #fff !important;
  font-weight: 600;
}
.history-comp .accordion .accordion-item .accordion-button {
  box-shadow: none;
}
.history-comp .accordion .accordion-item:first-child .accordion-button {
  font-size: 1.2rem !important;
  font-weight: 600;
}

.history-comp .accordion-body {
  min-height: 15vh;
  max-height: 30vh;
  overflow-y: auto;
  background-color: #222;
  cursor: grab;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.history-comp .accordion-body::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.history-comp .btn-show-up {
  position: absolute;
  right: 20px;
  top: 15px;
}

.show-up {
  display: block !important;
  animation-name: slidein;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}
@keyframes slidein {
  from {
    transform: translateX(300px);
  }
  to {
    transform: translateX(0px);
  }
}

@media (max-width: 1310px) {
  .accordion {
    transform: translateX(300px);
    display: none;
  }
}

.history-comp .copy-text {
  margin-right: 5px;
  opacity: 0.7;
}
.history-comp .copy-text:hover {
  color: #77b300;
  cursor: pointer;
  opacity: 1;
}

.history-comp .delete-text {
  margin-right: 5px;
  opacity: 0.7;
}
.history-comp .delete-text:hover {
  color: #77b300;
  cursor: pointer;
  opacity: 1;
}
