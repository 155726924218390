.form-comp {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 45vw;
  max-width: 650px;
  min-width: 400px;
  padding-bottom: 150px;
  transform: translateY(8vh);
}

.disable-scroll {
  height: calc(90vh);
  overflow-y: hidden;
  opacity: 0.5;
}

.form-comp .greeting-heading {
  padding-bottom: 20px;
}
@media (max-width: 1310px) {
  .form-comp .greeting-heading {
    font-size: 3.6vw;
  }
}
@media (max-height: 765px) {
  .form-comp {
    transform: translateY(0vh);
    top: 60px;
  }
}

.form-comp .results div h4 {
  margin: 55px 0 30px;
}

.form-comp .results div:last-child p {
  min-height: 100px;
  margin-bottom: 50px;
}
@media (min-width: 1310px) and (min-height: 800px) {
  .form-comp .results div:last-child p {
    min-height: 20vh;
  }
}

.form-comp .retrieve-answer.progress {
  margin: 20px 0 0;
}

.form-comp .btn-search {
  width: 150px;
}

.form-comp .spinner-grow {
  margin-right: 5px;
}

.form-comp .file-name-dispaly {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}
.form-comp .upload-file {
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
}
.form-comp .file-name-dispaly button {
  height: auto;
  font-size: 0.7rem;
  padding: 1px 5px;
  margin-left: 10px;
}

.form-comp .copy-text {
  margin-right: 5px;
  opacity: 0.7;
}
.form-comp .copy-text:hover {
  color: #77b300;
  cursor: pointer;
  opacity: 1;
}
