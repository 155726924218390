.contact-form-comp {
  width: 400px;
  margin: 50px 0;
}

.contact-form-comp .login-heading {
  text-align: center;
  margin-bottom: 25px;
}

.contact-form-comp .logo-icon {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
}

.contact-form-comp form fieldset button {
  margin-top: 50px;
}

.contact-form-comp form fieldset button span {
  margin-left: 5px;
}
