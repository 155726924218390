.copyright {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 210px;
  padding: 20px 0;
  font-size: 0.7rem;
  opacity: 0.2;
  margin: auto;
}
.copyright:hover {
  opacity: 0.3;
  cursor: default;
}

.copyright img {
  width: 25px;
  border-radius: 50%;
}

.copyright img:hover {
  cursor: pointer;
}
