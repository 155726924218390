.contact-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.contact-page .copyright {
  position: relative;
  margin-top: 50px;
}
